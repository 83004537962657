import moment, { Moment } from 'moment'
import momentTz from 'moment-timezone'

export enum DATE_TIME_FORMAT {
  DAY_MONTH_YEAR = 'DD/MM/YYYY',
  DATE_TIME = 'DD/MM/YYYY HH:mm A',
  DATE_TIME_UNDER_12 = 'DD/MM/YYYY hh:mm A',
  MAIN_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss',
  TIME_FORMAT = 'HH:mm:ss',
  YEAR_MONTH_DAY = 'YYYY-MM-DD'
}

export const getDubaiDateTimeMoment: any = (
  dateTime: string | number | Moment | undefined | null,
  outputFormat: string = DATE_TIME_FORMAT.MAIN_DATE_TIME_FORMAT,
  inputFormat: string = DATE_TIME_FORMAT.DAY_MONTH_YEAR
): Moment | null => {
  if (!dateTime) {
    return null
  }

  let m: string | number | Moment = ''

  if (typeof dateTime === 'number') {
    m = momentTz(dateTime).tz('Asia/Dubai')
  }

  if (typeof dateTime === 'string') {
    m = momentTz(dateTime, inputFormat).tz('Asia/Dubai')
  }

  return m && typeof m === 'object' ? m : null
}

export const getDubaiDateTime: any = (
  dateTime: string | number | Moment | undefined | null,
  outputFormat: string = DATE_TIME_FORMAT.MAIN_DATE_TIME_FORMAT,
  inputFormat: string = DATE_TIME_FORMAT.DAY_MONTH_YEAR
): string => {
  const m: string | Moment = getDubaiDateTimeMoment(
    dateTime,
    outputFormat,
    inputFormat
  )

  return m && typeof m === 'object' ? m.format(outputFormat) : ''
}

export const getLocalDateTimeMoment: any = (
  dateTime: string | number | Moment | undefined | null,
  outputFormat: string = DATE_TIME_FORMAT.MAIN_DATE_TIME_FORMAT,
  inputFormat: string = DATE_TIME_FORMAT.DAY_MONTH_YEAR
): Moment | null => {
  if (!dateTime) {
    return null
  }

  let m: string | number | Moment = ''

  if (typeof dateTime === 'number') {
    m = momentTz(dateTime).tz(moment.tz.guess().toString())
  }

  if (typeof dateTime === 'string') {
    m = momentTz(dateTime, inputFormat).tz(moment.tz.guess().toString())
  }

  return m && typeof m === 'object' ? m : null
}

export const getLocalDateTime: any = (
  dateTime: string | number | Moment | undefined | null,
  outputFormat: string = DATE_TIME_FORMAT.MAIN_DATE_TIME_FORMAT,
  inputFormat: string = DATE_TIME_FORMAT.DAY_MONTH_YEAR
): string => {
  const m: string | Moment = getLocalDateTimeMoment(
    dateTime,
    outputFormat,
    inputFormat
  )

  return m && typeof m === 'object' ? m.format(outputFormat) : ''
}

export const getTimestampParseWithCurrentTime: any = (
  dateTime: Moment
): number => {
  if (!dateTime) {
    return 0
  }

  let toDateValue: number = dateTime.valueOf()

  if (!dateTime.isSame(moment(), 'day')) {
    toDateValue = dateTime.endOf('day').valueOf()
  }

  return toDateValue
}
