import { FC, Suspense } from 'react'

import { Spin } from 'antd'

import { IProps } from './type.d'

const FallbackLoading: FC = (): JSX.Element => {
  return (
    <div className="fallback-loading">
      <Spin />
    </div>
  )
}

export const Content: FC<IProps> = ({ children }: IProps): JSX.Element => {
  return (
    <div className="gradient-layout__body__content">
      <Suspense fallback={<FallbackLoading />}>{children}</Suspense>
    </div>
  )
}
