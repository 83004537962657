import { createSlice } from '@reduxjs/toolkit'

export const prevPathSlice: any = createSlice({
  name: 'prevPath',
  initialState: {
    currentprevPath: null
  },
  reducers: {
    setCurrentPrevPath: (state: any, action: { payload: string }): void => {
      state.currentprevPath = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setCurrentPrevPath } = prevPathSlice.actions

export default prevPathSlice.reducer
