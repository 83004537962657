import { createSlice } from '@reduxjs/toolkit'

export const listNotAccessSlice: any = createSlice({
  name: 'listNotAccess',
  initialState: {
    currentListNotAccess: []
  },
  reducers: {
    setCurrentListNotAccess: (
      state: any,
      action: { payload: string[] }
    ): void => {
      state.currentListNotAccess = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setCurrentListNotAccess } = listNotAccessSlice.actions

export default listNotAccessSlice.reducer
