import {
  EOrderByFullText,
  TableColumnSortDirectionEnum,
  IFilter
} from 'components/tables/Common/type.d'

import { FILTER_TYPE } from 'components/tables/Common/type.d'

export interface ISortingParams {
  sortBy: string
  orderBy: EOrderByFullText
}

export interface IFixedFilterItem {
  field: string
  type: FILTER_TYPE
  value: string | number
}

export interface IAllFilterParams {
  keyword?: string
  searchField: IFilter | IFilter[]
  fixedFilter?: IFixedFilterItem[]
  advancedSearch?: IFilter[]
}
export interface IParamsToFetchList {
  page?: number
  pageSize?: number
  sortBy?: string
  orderBy?: EOrderByFullText | TableColumnSortDirectionEnum
  filter?: IAllFilterParams
  fixedPayload?: any // {}
  externalParams?: any // {}
  isDisablePaging?: boolean
}

export interface IParamsToFetchListHubSummary {
  page?: number
  pageSize?: number
  sortBy?: string
  orderBy?: EOrderByFullText | TableColumnSortDirectionEnum
  filter?: IAllFilterParams
  fixedPayload?: any // {}
  externalParams?: any // {}
  isDisablePaging?: boolean
  accountId: string
  nameSpoke: string
  period: string
  urlSpoke: string
}

export interface IParamsCardManagement {
  page?: number
  pageSize?: number
  sortBy?: string
  orderBy?: EOrderByFullText | TableColumnSortDirectionEnum
  filter?: IAllFilterParams
  fixedPayload?: any // {}
  externalParams?: any // {}
  isDisablePaging?: boolean
  accountId?: string
  nameSpoke?: string
  period?: string
  urlSpoke?: string
  workerId: string
}

export interface IPayloadCardManagement {
  workerId: string
}

export interface IPayloadToFetchList {
  offset: number
  limit: number
  orderBy: string
  filter: string
}

export interface IPagingPayload {
  offset?: number
  limit?: number
  sortBy?: string | any
  orderBy?: string
}

export const ErrorMessageMapping: { [ErrorCode as string]: string } = {
  'ER-1102': 'Please do not select a time in the past.',
  'ER-2102':
    'The file you have uploaded has transactions involving accounts from different countries.',
  'ER-1414': 'User with this Employee ID/Email ID already exists.'
}
