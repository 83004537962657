import { FC } from 'react'
import { Link } from 'react-router-dom'

import { LogoSVG } from './svg/Logo.jsx'
import { Line1SVG } from './svg/Line1.jsx'
import { Line2SVG } from './svg/Line2.jsx'
import { Line3SVG } from './svg/Line3.jsx'

import { IProps } from './type.d'

import './style.scss'

export const AuthenLayout: FC<IProps> = ({
  children,
  logoLink
}: IProps): JSX.Element => {
  return (
    <div className="authen-page">
      <div className="authen-page__left">
        <div className="authen-page__left__logo">
          <Link to={logoLink || '/'}>
            <LogoSVG />
            <p className="authen-page__left__logo__p">Metis Admin</p>
          </Link>

          <div className="authen-page__left__line1">
            <Line1SVG />
          </div>

          <div className="authen-page__left__line2">
            <Line2SVG />
          </div>

          <div className="authen-page__left__line3">
            <Line3SVG />
          </div>
        </div>
      </div>

      <div className="authen-page__right">{children}</div>
    </div>
  )
}

AuthenLayout.defaultProps = {
  logoLink: '/'
}
