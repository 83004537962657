import { http } from 'http/index'
// import { UserType } from 'services/user/type.d'
import { UserType } from 'services/user/type.d'
import store from 'redux/store'
import { setCurrentUser } from 'redux/reducers/userSlice'
import ApiService from 'services/api'
import { setCurrentListNotAccess } from 'redux/reducers/listAccessSlice'
import { setCurrentListCountry } from 'redux/reducers/listCountrySlice'
import { setCurrentListCountriesAccess } from 'redux/reducers/listCountriesAccessSlice'
import { setCurrentPrevPath } from 'redux/reducers/prevPathSlice'

export class UserService extends ApiService {
  getAllUsers(): Promise<any> {
    return http.fetch('/admin/relationship-managers')
  }

  getAllImplementationManager(): Promise<any> {
    return http.fetch('/admin/implementation-managers')
  }

  getPublicContent(): Promise<any> {
    return http.get('/all')
  }

  getUserBoard(): Promise<any> {
    return http.get('/user')
  }

  getModeratorBoard(): Promise<any> {
    return http.get('/mod')
  }

  getAdminBoard(): Promise<any> {
    return http.get('/admin')
  }

  getListModuleNotAccess(): Promise<any> {
    return http.get('/admin/un-access-groups')
  }

  getListCountriesAccess(): Promise<any> {
    return http.get('/admin/groups-countries')
  }

  // loadUserFromLocalStorage(): UserType | null {
  loadUserFromLocalStorage(): UserType | null {
    const userJson: string = localStorage.getItem('user') || ''
    // let user: UserType | null = null
    let user: UserType | null = null
    if (userJson) {
      user = JSON.parse(userJson)
    }

    return user || null
  }

  handleOnReceivedBroadcastLogin(): void {
    // const user: UserType | null = this.loadUserFromLocalStorage()
    const user: UserType | null = this.loadUserFromLocalStorage()
    this.setCurrentUser(user)
  }

  // pickCompactUserFromCognito(user: UserType | null): UserType | null {
  pickCompactUserFromCognito(user: UserType | null): UserType | null {
    return user
  }
  // setCurrentUser(user: UserType | null): void {
  //   const compactUser: UserType | null = this.pickCompactUserFromCognito(user)
  setCurrentUser(user: UserType | null): void {
    const compactUser: UserType | null = this.pickCompactUserFromCognito(user)
    store.dispatch(setCurrentUser(compactUser))
    localStorage.setItem('user', JSON.stringify(compactUser))
  }

  removeCurrentUser(): void {
    localStorage.removeItem('user')
    store.dispatch(setCurrentUser(null))
  }

  removePrevious(): void {
    sessionStorage.removeItem('prevPath')
    store.dispatch(setCurrentPrevPath(null))
  }

  removeListNonAccess(): void {
    localStorage.removeItem('listNonAccess')
    store.dispatch(setCurrentListNotAccess(null))
  }

  removeListCountry(): void {
    store.dispatch(setCurrentListCountry(null))
    store.dispatch(setCurrentListCountriesAccess(null))
  }

  getTokenFromLocalStorage(): string {
    // const user: UserType | null = this.loadUserFromLocalStorage()
    if (
      window.self !== window.top ||
      process.env.REACT_APP_IS_LOGIN_COGNITO === 'true'
    ) {
      const user: UserType | null = this.loadUserFromLocalStorage()
      return (user && user?.signInUserSession?.idToken?.jwtToken) || ''
    } else {
      const token: string | null = localStorage.getItem('idToken')
      return token ? token : ''
    }

    // return (user && user?.signInUserSession?.idToken?.jwtToken) || ''
  }
}
// eslint-disable-next-line
export default new UserService()
