export const Line2SVG = () => (
  <svg
    width="300px"
    height="300px"
    viewBox="350 170 300 300"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g
        id="3a_LoginScreen"
        transform="translate(-64.000000, -95.000000)"
        fillRule="nonzero"
      >
        <g id="Metis-bg" transform="translate(64.500427, 95.992531)">
          <g
            id="Group-14"
            style={{
              mixBlendMode: 'color-burn'
            }}
            transform="translate(499.806378, 260.007337) rotate(111.000000) translate(-499.806378, -260.007337) translate(384.168573, 166.971469)"
          >
            <ellipse
              id="Oval-2"
              fill="#CFDAE6"
              opacity={0.25}
              cx={30.6100063}
              cy={30.6337605}
              rx={30.6100063}
              ry={30.6337605}
            />
            <ellipse
              id="Oval-2"
              fill="#CFDAE6"
              opacity={0.300000012}
              cx={30.6100069}
              cy={30.6337615}
              rx={22.6740799}
              ry={22.6916752}
            />
            <ellipse
              id="Oval-2-Copy"
              stroke="#CFDAE6"
              opacity={0.400000006}
              cx={188.194843}
              cy={145.226726}
              rx={24.9414864}
              ry={24.9608421}
            />
            <ellipse
              id="Oval-2"
              fill="#BACAD9"
              opacity={0.5}
              cx={30.6100064}
              cy={30.633761}
              rx={11.3370399}
              ry={11.3458376}
            />
            <path
              d="M30.6100401,41.9796729 L30.6100401,19.2879996 C24.3487657,19.2879996 19.2730001,24.3677044 19.2730001,30.6338363 C19.2730001,36.8999705 24.3487657,41.9796729 30.6100401,41.9796729 Z"
              id="Oval-2"
              fill="#BACAD9"
            />
            <g
              id="Group-9"
              opacity={0.449999988}
              transform="translate(181.393000, 138.419000)"
              fill="#E6A1A6"
            >
              <ellipse
                id="Oval-2-Copy-2"
                cx={6.80222368}
                cy={6.80750275}
                rx={6.80222368}
                ry={6.80750275}
              />
              <path
                d="M6.80222359,13.6150045 C3.04545927,13.6150045 6.88338275e-15,10.5671825 6.88338275e-15,6.80750227 C6.88338275e-15,3.04782271 3.04545927,0 6.80222359,0 L6.80222359,13.6150045 Z"
                id="Oval-2-Copy-2"
              />
            </g>
            <ellipse
              id="Oval-10"
              fill="#CFDAE6"
              cx={229.575052}
              cy={145.794023}
              rx={1.70055592}
              ry={1.70187569}
            />
            <ellipse
              id="Oval-10-Copy-2"
              fill="#E0B4B7"
              opacity={0.349999994}
              cx={188.761713}
              cy={184.369859}
              rx={1.70055592}
              ry={1.70187569}
            />
            <ellipse
              id="Oval-10-Copy"
              fill="#E0B4B7"
              opacity={0.349999994}
              cx={189.328565}
              cy={107.785456}
              rx={2.26740789}
              ry={2.26916766}
            />
            <line
              x1={30.4850001}
              y1={31.5029996}
              x2={179.923183}
              y2={139.686222}
              id="Path-11"
              stroke="#CFDAE6"
              opacity={0.800000012}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
