import { AxiosResponse } from 'axios'
import { http } from 'http/index'
import ApiService from 'services/api'
import { ITypeConfig } from './type'

export class ConfigInfoService extends ApiService {
  getConfigInfo(): Promise<AxiosResponse<ITypeConfig>> {
    return http.get(`/admin/config`)
  }
}
// eslint-disable-next-line
export default new ConfigInfoService()
