import React from 'react'

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error: any): void {
    this.setState({
      error
    })
  }

  render(): any {
    if (this.state.error) {
      return (
        <div>
          <h2>Something went wrong.</h2>
        </div>
      )
    }

    return this.props.children
  }
}

export { ErrorBoundary }
