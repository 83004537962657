import { createSlice } from '@reduxjs/toolkit'

export const listCountriesAccessSlice: any = createSlice({
  name: 'listCountriesAccess',
  initialState: {
    currentListCountriesAccess: []
  },
  reducers: {
    setCurrentListCountriesAccess: (
      state: any,
      action: { payload: string[] }
    ): void => {
      state.currentListCountriesAccess = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setCurrentListCountriesAccess } =
  listCountriesAccessSlice.actions

export default listCountriesAccessSlice.reducer
