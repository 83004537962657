import { createSlice } from '@reduxjs/toolkit'

export const idTokenSlice: any = createSlice({
  name: 'idToken',
  initialState: {
    currentIdToken: null
  },
  reducers: {
    setCurrentIdToken: (state: any, action: { payload: string }): void => {
      state.currentIdToken = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setCurrentIdToken } = idTokenSlice.actions

export default idTokenSlice.reducer
