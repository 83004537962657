import React from 'react'

import { LAYOUT_NAME } from 'layouts/type.d'
import { IRouteConfigProps, ROUTER_NAME_LIST } from 'router/type.d'

export interface IRouteContext {
  routeConfig: IRouteConfigProps
}

const initialValues: IRouteContext = {
  routeConfig: {
    layout: LAYOUT_NAME.ADMIN,
    name: ROUTER_NAME_LIST.DASHBOARD
  }
}

export const RouteContext: React.Context<IRouteContext> =
  React.createContext(initialValues)
