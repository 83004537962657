import { createSlice } from '@reduxjs/toolkit'

import { SpinStateType } from 'redux/type'

export const spinSlice: any = createSlice({
  name: 'spin',
  initialState: {
    isShowGlobalSpin: false
  },
  reducers: {
    setGlobalSpin: (
      state: SpinStateType,
      action: { payload: boolean }
    ): void => {
      state.isShowGlobalSpin = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setGlobalSpin } = spinSlice.actions

export default spinSlice.reducer
