import moment, { Moment } from 'moment'
import { DATE_TIME_FORMAT, getLocalDateTime } from 'helpers/datetime'
import lodash from 'lodash'

const LAST_TIME_HAS_ACTION_ON_BROWSER: string =
  'LAST_TIME_HAS_ACTION_ON_BROWSER'

export const getLastTimeHasActionOnBrowser: any = (): Moment | null => {
  const time: string | null = localStorage.getItem(
    LAST_TIME_HAS_ACTION_ON_BROWSER
  )

  if (!time) {
    return null
  }

  const timeMoment: Moment = moment(
    time,
    DATE_TIME_FORMAT.MAIN_DATE_TIME_FORMAT
  )

  const isValidTime: boolean = timeMoment.isValid()
  if (isValidTime) {
    return timeMoment
  }

  return null
}

export const setLastTimeHasActionOnBrowser: any = (): void => {
  const current: string = moment().format(
    DATE_TIME_FORMAT.MAIN_DATE_TIME_FORMAT
  )

  const date: any = new Date()

  const dateToCompare: number = date.getTime()

  const dateToGet: string = getLocalDateTime(
    dateToCompare,
    DATE_TIME_FORMAT.MAIN_DATE_TIME_FORMAT
  )

  if (lodash.isEqual(current, dateToGet)) {
    localStorage.setItem(LAST_TIME_HAS_ACTION_ON_BROWSER, current)
  } else {
    localStorage.setItem(LAST_TIME_HAS_ACTION_ON_BROWSER, dateToGet)
  }
}
