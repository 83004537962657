import { Amplify } from 'aws-amplify'

Amplify.configure({
  Auth: {
    // // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

    // REQUIRED - Amazon Cognito Region

    region: process.env.REACT_APP_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID

    userPoolId: process.env.REACT_APP_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)

    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH' | 'CUSTOM_AUTH'
  }
})
