import { createSlice } from '@reduxjs/toolkit'

export const listCountrySlice: any = createSlice({
  name: 'listCountry',
  initialState: {
    currentListCountry: []
  },
  reducers: {
    setCurrentListCountry: (
      state: any,
      action: { payload: string[] }
    ): void => {
      state.currentListCountry = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setCurrentListCountry } = listCountrySlice.actions

export default listCountrySlice.reducer
