import { createSlice } from '@reduxjs/toolkit'
import { IsDeactiveUserStateType } from 'redux/type'

export const isDeactiveUserSlice: any = createSlice({
  name: 'isDeactiveUser',
  initialState: {
    isDeactiveUser: false
  },
  reducers: {
    setIsDeactiveUser: (
      state: IsDeactiveUserStateType,
      action: { payload: boolean }
    ): void => {
      state.isDeactiveUser = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setIsDeactiveUser } = isDeactiveUserSlice.actions

export default isDeactiveUserSlice.reducer
