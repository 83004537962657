import { FC, useEffect } from 'react'

import { v4 as uuid } from 'uuid'

import { useRouterHistories } from 'hooks'
import { IUseRouterHistories } from 'hooks/type'
import AuthService from 'services/user/auth.service'
import UserService from 'services/user/user.service'
import { BROADCAST_CHANNEL } from 'services/broadcast'

import { BROADCAST_CHANNEL_TYPES } from 'services/broadcast/type.d'

const windowTabId: string = uuid()
const LOCAL_STORAGE_TAB_IDS_KEY: string = 'LOCAL_STORAGE_TAB_IDS_KEY'
const LOCAL_STORAGE_TAB_ID_LASTEST: string = 'LOCAL_STORAGE_TAB_ID_LASTEST'

export const BroadcastListener: FC = (): JSX.Element => {
  const histories: IUseRouterHistories = useRouterHistories()

  const getIdListFromLocalStorage: any = (): void => {
    const currentTabIdList: string | null = localStorage.getItem(
      LOCAL_STORAGE_TAB_IDS_KEY
    )
    return JSON.parse(currentTabIdList || '[]')
  }

  const setCurrentIdToLocalStorage: any = (): void => {
    const tabs: string[] = getIdListFromLocalStorage()
    if (!tabs?.includes(windowTabId)) {
      tabs.push(windowTabId)
    }

    localStorage.setItem(LOCAL_STORAGE_TAB_IDS_KEY, JSON.stringify(tabs))
  }

  const removeCurrentIdFromLocalStorage: any = (): void => {
    let tabs: string[] = getIdListFromLocalStorage()
    tabs = tabs.filter((id: string): boolean => id !== windowTabId)
    if (tabs.length === 1) {
      localStorage.setItem(LOCAL_STORAGE_TAB_ID_LASTEST, tabs[0])
    }
    localStorage.setItem(LOCAL_STORAGE_TAB_IDS_KEY, JSON.stringify(tabs))
  }

  const logoutIfTabIdListIsBlank: any = (): void => {
    const tabs: string[] = getIdListFromLocalStorage()

    const isNeedLogout: boolean = !tabs.length && !window.name
    if (isNeedLogout) {
      AuthService.logoutAzure()
    }
  }

  const handleOnUnloadPage: any = (): void => {
    removeCurrentIdFromLocalStorage()

    BROADCAST_CHANNEL.postMessage({
      type: BROADCAST_CHANNEL_TYPES.CLOSED_TAB,
      payload: {
        tabId: windowTabId
      }
    })
  }

  const login: any = (): void => {
    UserService.handleOnReceivedBroadcastLogin()

    histories.goToAdminPreferPrevPage()
  }

  const logout: any = (): void => {
    AuthService.logoutAzure()
  }

  const handleOnReceivedBroadcastMessage: any = (event: any): void => {
    if (!event || !event.type) {
      return
    }

    if (event.type === BROADCAST_CHANNEL_TYPES.CLOSED_TAB) {
      logoutIfTabIdListIsBlank()
    }

    if (event.type === BROADCAST_CHANNEL_TYPES.SIGN_IN) {
      login()
    }

    if (event.type === BROADCAST_CHANNEL_TYPES.SIGN_OUT) {
      logout()
    }
  }

  useEffect((): any => {
    // logoutIfTabIdListIsBlank()
    window.name = `METIS_APP:${windowTabId}`

    setCurrentIdToLocalStorage()

    window.addEventListener('unload', handleOnUnloadPage)
    BROADCAST_CHANNEL.onmessage = handleOnReceivedBroadcastMessage

    return (): void => {
      removeCurrentIdFromLocalStorage()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <></>
}
