import { FC } from 'react'
import 'aws'

import { BrowserRouter as Router } from 'react-router-dom'
import { Layout } from 'layouts/index'
import { ErrorBoundary } from 'HOC/ErrorBoundary'

import { BackgroundTasks } from 'components/Backgrounds'

import 'scss/index.scss'

interface IProps {}

const App: FC<IProps> = (): JSX.Element => {
  return (
    <ErrorBoundary>
      <Router>
        <BackgroundTasks />
        <Layout />
      </Router>
    </ErrorBoundary>
  )
}

export default App
