import { notification } from 'antd'
import {
  WarningOutlined,
  CheckCircleOutlined,
  WarningFilled
} from '@ant-design/icons'
import { ErrorMessageMapping } from 'services/api/type.d'
import { isDeactive } from 'http/helper'

export const showDeactiveMessage: any = (message: string): void => {
  notification.error({
    top: 90,
    icon: <WarningFilled />,
    closeIcon: <></>,
    message
  })
}

export const showErrorMessage: any = (
  message: string,
  description: string = ''
): void => {
  notification.error({
    top: 90,
    icon: <WarningOutlined />,
    message,
    description
  })
}

export const showSuccessMessage: any = (
  message: string,
  description: string = ''
): void => {
  notification.success({
    top: 90,
    icon: <CheckCircleOutlined />,
    message,
    description
  })
}

export const showErrorMessageResponseAPI: any = (error: any): void => {
  const mesDeactive: boolean = isDeactive(error)
  if (!mesDeactive) {
    const errorCode: string = error?.response?.data?.details?.msgCode
    const errorMsg: string =
      error?.response?.data?.details?.message || 'Please try again'
    const errorMessage: string = ErrorMessageMapping[errorCode] || errorMsg

    showErrorMessage('Error', errorMessage)
  }
}
