export const LogoSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="65"
    height="41"
    viewBox="0 0 65 41"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.411" stopColor="#fff" stopOpacity="0.6" />
        <stop offset="1" stopColor="#fff" stopOpacity="0.6" />
        <stop offset="1" stopColor="#1b1464" />
      </linearGradient>
    </defs>
    <g id="logo-version" transform="translate(0 -0.425)">
      <path
        id="Shape"
        d="M0,41l1.349-2.434,13.309-24.2L20.1,24.151l-8.8,16.006L10.843,41Zm36.2-.047-.465-.842-8.1-14.7-3.955-7.208-.14-.281L18.1,8.144V8.1l.419-.8L22.058.889,22.476,0H24.57l.419.889L28.526,7.3,45.744,38.566,47,40.953Z"
        transform="translate(18 0.425)"
        fill="url(#linear-gradient)"
      />
      <path
        id="Shape-2"
        data-name="Shape"
        d="M0,41l1.349-2.434,13.309-24.2L20.1,24.151l-8.8,16.006L10.843,41Zm36.2-.047-.465-.842-8.1-14.7-3.955-7.208-.14-.281L18.1,8.144V8.1l.419-.8L22.058.889,22.476,0H24.57l.419.889L28.526,7.3,45.744,38.566,47,40.953Z"
        transform="translate(0 0.425)"
        fill="url(#linear-gradient)"
      />
    </g>
  </svg>
)
