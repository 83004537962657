export const LogoFullColorSVGIcon = () => (
  <svg
    id="logo-version"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="33.293"
    height="24.136"
    viewBox="0 0 33.293 24.136"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#4a90e2" />
        <stop offset="1" stopColor="#50e3c2" />
      </linearGradient>
    </defs>
    <path
      id="Shape"
      d="M0,24.136.691,22.7,7.508,8.458,10.3,14.217l-4.5,9.423-.238.5Zm18.543-.028-.238-.5-4.147-8.651-2.026-4.243-.072-.165L9.272,4.794V4.767L9.486,4.3,11.3.524,11.512,0h1.073L12.8.524,14.611,4.3,23.43,22.7l.644,1.405Z"
      transform="translate(9.22)"
      fill="url(#linear-gradient)"
    />
    <path
      id="Shape-2"
      data-name="Shape"
      d="M0,24.136.691,22.7,7.508,8.458,10.3,14.217l-4.5,9.423-.238.5Zm18.543-.028-.238-.5-4.147-8.651-2.026-4.243-.072-.165L9.272,4.794V4.767L9.486,4.3,11.3.524,11.512,0h1.073L12.8.524,14.611,4.3,23.43,22.7l.644,1.405Z"
      fill="url(#linear-gradient)"
    />
  </svg>
)
