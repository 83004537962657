import { AxiosResponse } from 'axios'
import { http } from 'http/index'
import { IFormData } from 'pages/settings/tabs/User/pages/CreateNewUser/type'
import ApiService from 'services/api'
import { IParamsToFetchList, IPayloadToFetchList } from 'services/api/type'
import {
  IPayloadChangeStatusUser,
  IResponseListRequestUser,
  IResponseListUser,
  IResponseRequestUserDetail,
  IResponseUserDetail
} from './type.d'

export class UserSettingService extends ApiService {
  getListGroup(params: IParamsToFetchList): Promise<IResponseListUser> {
    const payload: IPayloadToFetchList =
      ApiService.getPayLoadToFetchList(params)

    return http.fetch('/admin/support-user', payload)
  }

  getListRequestGroup(
    params: IParamsToFetchList
  ): Promise<IResponseListRequestUser> {
    const payload: IPayloadToFetchList =
      ApiService.getPayLoadToFetchList(params)

    return http.fetch('/admin/mc/support-user', payload)
  }

  getUserDetail(id: string): Promise<AxiosResponse<IResponseUserDetail>> {
    return http.fetch(`/admin/support-user/${id}`)
  }

  getRequestUserDetail(
    id: string
  ): Promise<AxiosResponse<IResponseRequestUserDetail>> {
    return http.fetch(`admin/mc/support-user/${id}`)
  }

  approveOrRejectRequest(payload: IPayloadChangeStatusUser): Promise<any> {
    return http.post('/admin/mc/check', payload)
  }

  createUser(payload: IFormData): Promise<undefined> {
    return http.post('/admin/mc/support-user', payload)
  }

  editUser(id: string, payload: IFormData): Promise<undefined> {
    return http.post(`/admin/mc/support-user/${id}`, payload)
  }

  getUserRequestDetail(
    id: string
  ): Promise<AxiosResponse<IResponseListRequestUser>> {
    return http.fetch(`/admin/groups/${id}`)
  }

  getFlagNonWps(): Promise<any> {
    return http.fetch('admin/payroll-sender-feature-flag')
  }
}
// eslint-disable-next-line
export default new UserSettingService()
