import { FC } from 'react'

import AuthService from 'services/user/auth.service'

import { IProps } from './type'

import './style.scss'

export const LogoutPopup: FC<IProps> = ({
  isOpen,
  setIsOpen
}: IProps): JSX.Element => {
  const cancel: any = (): void => {
    setIsOpen(false)
  }

  const logout: any = (): void => {
    AuthService.logoutAllTabs()
  }

  return (
    <>
      {isOpen && (
        <div className="logout-confirm-popup">
          <div className="logout-confirm-popup__wrap">
            <div className="logout-confirm-popup_wrap__overlay" />

            <div className="logout-confirm-popup_wrap__content">
              <div className="logout-confirm-popup_wrap__content__logo">
                <img src="/imgs/svg/primary-gradient/logout.png" alt="" />
              </div>

              <div className="logout-confirm-popup_wrap__content__question">
                <div className="logout-confirm-popup_wrap__content__question__title">
                  Are you sure you want to log out?
                </div>

                <div className="logout-confirm-popup_wrap__content__question__detail">
                  All the unsaved changes will be deleted. Click cancel to
                  continue working.
                </div>
              </div>

              <div className="logout-confirm-popup_wrap__content__btn">
                <button
                  className="logout-confirm-popup_wrap__content__btn__cancel"
                  onClick={cancel}
                >
                  Cancel
                </button>

                <button
                  className="logout-confirm-popup_wrap__content__btn__logout"
                  onClick={logout}
                >
                  Log out
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

LogoutPopup.defaultProps = {
  isOpen: false
}
