import { map } from 'lodash'
import { cookPagingPayload } from 'helpers/http'

import { IAllFilterParams, IPagingPayload, IParamsToFetchList } from './type'
import { IFilter } from 'components/tables/Common/type'

export default class ApiService {
  static generateFilter(filters: IAllFilterParams): string {
    const result: string[] = []
    let searchFields: IFilter[] = []

    // filter when doing a search
    if (filters && filters.keyword) {
      if (!Array.isArray(filters.searchField)) {
        searchFields.push(filters.searchField)
      } else {
        searchFields = [...filters.searchField]
      }
      // set value for filter object
      searchFields.forEach((element: IFilter): any => {
        element.value = filters.keyword
      })
    }

    const filterList: IFilter[] = [
      ...(Array.isArray(filters?.fixedFilter) ? filters?.fixedFilter : []),
      ...searchFields
    ]

    const generateValue: any = (filterObject: any): string => {
      if (Array.isArray(filterObject.value)) {
        return map(filterObject.value, (element: string | number): string => {
          return `${filterObject.field}:${filterObject.type}${element}`
        }).join(',')
      }

      return `${filterObject.field}:${filterObject.type}${filterObject.value}`
    }

    filterList.forEach((item: IFilter): void => {
      result.push(generateValue(item))
    })

    return result.join(',')
  }

  static getPayLoadToFetchList(params: IParamsToFetchList | any): any {
    const filter: string = ApiService.generateFilter(params?.filter)

    const paging: IPagingPayload = cookPagingPayload({
      pageSize: params?.isDisablePaging ? undefined : 10,
      ...params
    })

    const payload: any = filter.length
      ? {
          ...paging,
          filter
        }
      : {
          ...paging
        }

    return { ...payload, ...params?.fixedPayload }
  }

  static downloadCsvFromResponse(response: any, fileName?: string): void {
    const url: string = window.URL.createObjectURL(new Blob([response.data]))

    const fileNameInHeader: string = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .split(';')[1]
          .split('=')[1]
          .replace('"', '')
          .replace('"', '')
      : `file.csv`

    const link: HTMLAnchorElement = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName || fileNameInHeader)
    document.body.appendChild(link)
    link.click()
  }

  static downloadFileFromS3Response(res: any): void {
    const {
      data: { fileName, url }
    } = res

    const link: HTMLAnchorElement = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  }
}
