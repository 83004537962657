import { IFormData } from 'pages/settings/tabs/GroupName/pages/CreateGroup/type'
import ApiService from 'services/api'
import { http } from 'http/index'
import { IParamsToFetchList, IPayloadToFetchList } from 'services/api/type'
import {
  IElementAccessRightList,
  IPayloadChangeStatusGroup,
  IResponseGroupDetail,
  IResponseListDeactivateGroup,
  IResponseListGroup,
  IResponseListGroupTask,
  IResponseListRequestGroup
} from './type'
import { AxiosResponse } from 'axios'
import { IFormDataEdit } from 'pages/settings/tabs/GroupName/pages/EditUserGroup/type.d'

export class UserGroupService extends ApiService {
  static getListGroupDetail: any
  createGroup(payload: IFormData): Promise<undefined> {
    return http.post('/admin/mc/groups', payload)
  }

  getInfoOfRequestEditUserGroup(
    accountId: string
  ): Promise<AxiosResponse<any>> {
    return http.fetch(`admin/mc/spoke/accounts/${accountId}`)
  }

  getListGroup(params: IParamsToFetchList): Promise<IResponseListGroup> {
    const payload: IPayloadToFetchList =
      ApiService.getPayLoadToFetchList(params)

    return http.fetch('/admin/groups', payload)
  }

  getListRequestGroup(
    params: IParamsToFetchList
  ): Promise<IResponseListRequestGroup> {
    const payload: IPayloadToFetchList =
      ApiService.getPayLoadToFetchList(params)

    return http.fetch('/admin/mc/groups', payload)
  }

  getListGroupToCreateUser(): Promise<AxiosResponse<IResponseListGroup[]>> {
    return http.fetch(`/admin/active-groups`)
  }

  getListAccessRight(): Promise<AxiosResponse<IElementAccessRightList[]>> {
    return http.fetch(`/admin/access-right/flat`)
  }

  getListAccessRightTree(): Promise<AxiosResponse<IElementAccessRightList[]>> {
    return http.fetch(`/admin/access-right/tree`)
  }

  getListGroupDetail(id: string): Promise<AxiosResponse<IResponseGroupDetail>> {
    return http.fetch(`/admin/groups/${id}`)
  }

  getRequestDetail(id: string): Promise<AxiosResponse<IResponseListGroupTask>> {
    return http.fetch(`/admin/mc/groups/${id}`)
  }

  approveOrRejectRequest(payload: IPayloadChangeStatusGroup): Promise<any> {
    return http.post('/admin/mc/check', payload)
  }

  editUserGroup(id: string, payload: IFormDataEdit): Promise<any> {
    return http.post(`/admin/groups/${id}`, payload)
  }

  editRequestUserGroup(id: string, payload: IFormDataEdit): Promise<any> {
    return http.post(`/admin/mc/groups/${id}`, payload)
  }
  getUserDeativatedGroup(): Promise<
    AxiosResponse<IResponseListDeactivateGroup[]>
  > {
    return http.fetch(`/admin/deactivated-groups`)
  }
}
// eslint-disable-next-line
export default new UserGroupService()
