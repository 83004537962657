import { http } from 'http/index'
import { Auth } from 'aws-amplify'

import UserService from './user.service'
import { BROADCAST_CHANNEL } from 'services/broadcast'

import { BROADCAST_CHANNEL_TYPES } from 'services/broadcast/type.d'
class AuthService {
  login(username: string, password: string): Promise<any> {
    return Auth.signIn(username, password).then((response: any): any => {
      UserService.setCurrentUser(response)
      return response
    })
  }

  logoutAzure(): void {
    Auth.signOut()
    UserService.removeCurrentUser()
    UserService.removeListNonAccess()
    UserService.removeListCountry()
    UserService.removePrevious()
    if (
      window.self === window.top &&
      process.env.REACT_APP_IS_LOGIN_COGNITO !== 'true'
    ) {
      // &response_type=${
      //   process.env.REACT_APP_RESPONSE_TYPE_LOGOUT
      // }&scope=aws.cognito.signin.user.admin+email+openid+phone+profile
      window.location.href = `${
        process.env.REACT_APP_URL_DOMAIN_COGNITO_LOGOUT
      }?client_id=${process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID}
      &redirect_uri=${encodeURIComponent(
        `${process.env.REACT_APP_REDIRECT_URL}`
      )}&logout_uri=${encodeURIComponent(
        `${process.env.REACT_APP_REDIRECT_URL}logout`
      )}`
    }
  }

  logout(): void {
    Auth.signOut()
    UserService.removeCurrentUser()
    UserService.removeListNonAccess()
    UserService.removeListCountry()
    UserService.removePrevious()
  }

  logoutAllTabs(): void {
    BROADCAST_CHANNEL.postMessage({
      type: BROADCAST_CHANNEL_TYPES.SIGN_OUT
    })
    this.logoutAzure()
  }

  register(username: string, email: string, password: string): Promise<any> {
    return http.post('/signup', {
      username,
      email,
      password
    })
  }

  completePassword(user: any, newPassword: string): Promise<any> {
    return Auth.completeNewPassword(user, newPassword, {
      name: 'UserName',
      phone_number: '+1234567890'
    })
  }

  changePassword(oldPassword: string, newPassword: string): Promise<any> {
    return Auth.currentAuthenticatedUser().then((user: any): any => {
      return Auth.changePassword(user, oldPassword, newPassword)
    })
  }
}
// eslint-disable-next-line
export default new AuthService()
