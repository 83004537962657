import { FC, useLayoutEffect, useMemo } from 'react'

import { pick } from 'lodash'

import RouterService from 'services/router/index.service'
import { RouteContext } from './routeContext'

import { IProps } from './type'
import { IRouteConfigProps } from 'router/type'

export const PageWrapper: FC<IProps> = ({
  Page,
  pageProps,
  routeConfig,
  location
}: IProps): JSX.Element => {
  useLayoutEffect((): void => {
    RouterService.handleAfterRouteEnter(routeConfig, location)
  }, [routeConfig, location])

  const currentRoute: IRouteConfigProps = useMemo((): IRouteConfigProps => {
    return pick(routeConfig, [
      'path',
      'isPrivate',
      'layout',
      'adminActiveMenuKey',
      'name'
    ])
  }, [routeConfig])

  return Page ? (
    <RouteContext.Provider
      value={{
        routeConfig: currentRoute
      }}
    >
      <Page {...pageProps} />
    </RouteContext.Provider>
  ) : (
    <span />
  )
}
