import { createSlice } from '@reduxjs/toolkit'

export enum flagKey {
  NON_WPS = 'NON_WPS'
}

export const featureFlagSlice: any = createSlice({
  name: 'featureFlag',
  initialState: {
    [flagKey.NON_WPS]: true
  },
  reducers: {
    setFeatureFlag: (
      state: any,
      action: { payload: { value: boolean; keyName: flagKey } }
    ): void => {
      return {
        ...state,
        [action.payload.keyName]: action.payload.value
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setFeatureFlag } = featureFlagSlice.actions

export default featureFlagSlice.reducer
