import { FC, useMemo, useState, ReactElement } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
// import { useHistory } from 'react-router-dom'

import { Dropdown, Menu } from 'antd'

import iconProfileSetting from 'imgs/common/icon_profile_setting.png'
// import iconChangePassword from 'imgs/common/icon_change_password.png'
import iconLogout from 'imgs/common/icon_logout.png'
import { LogoutPopup } from 'components/popups/Logout'

import { StoreType } from 'redux/type'
import { IProps } from './type'
// import { UserType } from 'services/user/type.d'
import { UserType } from 'services/user/type.d'

export const NavBar: FC<IProps> = ({ isCollapsed }: IProps): JSX.Element => {
  const [showConfirmLogout, setShowConfirmLogout] = useState(false)
  enum MENU_ITEM {
    PROFILE_SETTING = 'PROFILE_SETTING',
    CHANGE_PASSWORD = 'CHANGE_PASSWORD',
    LOG_OUT = 'LOU_OUT'
  }
  // const user: UserType = useSelector(
  //   (state: StoreType): UserType => state.user.currentUser,
  const user: UserType = useSelector(
    (state: StoreType): UserType => state.user.currentUser,
    shallowEqual
  )

  const classes: string = useMemo((): string => {
    return `gradient-layout__nav ${isCollapsed ? '-collapsed' : ''}`
  }, [isCollapsed])

  // const history: any = useHistory()

  // const handleOnChangePasswordClick: any = (): void => {
  //   history.push('/change-password')
  // }

  const handleOnLogoutClick: any = (): void => {
    setShowConfirmLogout(true)
  }

  const userInfo: any = useMemo((): any => {
    return {
      avatar: '',
      // name: user?.attributes?.name || ''
      name: user?.name || user?.attributes?.name || ''
    }
  }, [user])

  const menu: ReactElement = (
    <Menu>
      <Menu.Item key={MENU_ITEM.PROFILE_SETTING}>
        <img src={iconProfileSetting} alt="" />
        <span>Profile Settings</span>
      </Menu.Item>

      {/* <Menu.Item
        key={MENU_ITEM.CHANGE_PASSWORD}
        onClick={handleOnChangePasswordClick}
      >
        <img src={iconChangePassword} alt="" />
        <span>Change Password</span>
      </Menu.Item> */}

      <Menu.Item key={MENU_ITEM.LOG_OUT} onClick={handleOnLogoutClick}>
        <img src={iconLogout} alt="" />
        <span>Log out</span>
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <LogoutPopup
        isOpen={showConfirmLogout}
        setIsOpen={setShowConfirmLogout}
      />

      <div className={classes}>
        <div className="gradient-layout__nav__left">
          <div className="gradient-layout__nav__left__shape-round" />
        </div>

        <div className="gradient-layout__nav__right">
          <Dropdown overlay={menu} trigger={['click']}>
            <div className="gradient-layout__nav__right__user">
              <img src="/imgs/svg/light-black/avatar-default.svg" alt="" />

              <div className="gradient-layout__nav__right__user__name">
                {userInfo.name}
              </div>
            </div>
          </Dropdown>
        </div>
      </div>
    </>
  )
}
