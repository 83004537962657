import { AxiosResponse } from 'axios'
import { v4 as uuid } from 'uuid'

import { EOrderByFullText, EOrderBy } from 'components/tables/Common/type.d'
import ApiService from 'services/api'
import { IPayloadToFetchList } from 'services/api/type'

interface IPagingParams {
  page?: number
  pageSize?: number
  sortBy?: string | any
  orderBy?: EOrderByFullText
}

export const cookPagingPayload: any = ({
  page,
  pageSize = 10,
  sortBy,
  orderBy
}: IPagingParams): {
  offset: number
  limit: number
  sortBy?: string | any
  orderBy?: EOrderBy
} => {
  const currentPage: number = !page || page < 1 ? 1 : page

  const offset: number = currentPage - 1

  let _orderBy: string | undefined

  if (orderBy) {
    _orderBy = orderBy === 'ascend' ? 'asc' : 'desc'
  }

  const sortObj: any = {}
  if (sortBy && orderBy) {
    sortObj.orderBy = `${sortBy}:${_orderBy}`
  }

  return {
    offset,
    limit: pageSize,
    ...sortObj
  }
}

export const addUuidToResponseData: any = (
  response: AxiosResponse
): AxiosResponse => {
  const data: any = response.data

  data.forEach((request: any): void => {
    request['__uuid'] = uuid()
  })

  return response
}

export const getOrderByDefault: any = (
  sortBy: string = 'createdAt',
  orderBy: EOrderByFullText = EOrderByFullText.descend
): string => {
  const payload: IPayloadToFetchList = ApiService.getPayLoadToFetchList({
    sortBy,
    orderBy
  })

  return payload.orderBy
}
